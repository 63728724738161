/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import moment from "moment";
import queryString from "query-string";
import {
  Button,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

import images from "../assets/img/images";
import { EgoMap } from "../assets/img/images";
import logo from "../assets/img/itlogo.png";
import arrow from "../assets/img/downarrow.png";

import { getData, setUser } from "../actions/apis";

class Sphinx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingProgress: false,
      images: [],
      cardData: {},
      name: "",
      email: "",
      dob: "",
      day: "",
      month: "",
      year: "",
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ images });
    const vars = queryString.parse(this.props.location.search);
    let name,
      email,
      dob,
      dobFormatted,
      dobObject,
      dayFormatted,
      monthFormatted,
      yearFormatted;
    if (vars.name !== undefined) name = vars.name;
    if (vars.email !== undefined) email = vars.email;
    let nameFormatted = "";
    if (name !== undefined) {
      nameFormatted = name
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }
    if (vars.dob !== undefined) {
      dob = vars.dob;
      dobFormatted = moment(dob);
      dobObject = moment(dobFormatted).toObject();
      dayFormatted = moment(dobFormatted).format("Do");
      monthFormatted = moment(dobFormatted).format("MMM");
      yearFormatted = moment(dobFormatted).format("YYYY");
      this.setState({
        name: nameFormatted,
        email,
        dob: dobObject,
        day: dayFormatted,
        month: monthFormatted,
        year: yearFormatted,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeCards = (e) => {
    e.preventDefault();
    let dob, dobFormatted;
    let { name, email, year, month, day } = this.state;
    dob = moment({ y: year, M: month, d: day });
    dobFormatted = moment(dob).toObject();
    this.setState({
      dob: dobFormatted,
    });
    let userData = {
      name: name,
      email: email,
      dob: dob.toDate(),
    };
    let basicInputData = {
      birthYear: dobFormatted.years,
      birthMonth: dobFormatted.months + 1,
      birthDay: dobFormatted.date,
    };
    this.setState({
      showingProgress: true,
    });
    getData(basicInputData).then((res) => {
      this.setState({
        cardData: {
          numbers: res.numbers,
          names: res.names,
          keywords: res.keywords,
        },
        showCardOutput: true,
      });
      userData.cards = res.numbers;
      setUser(userData);
    });
    setTimeout(() => {
      this.setState({
        showingProgress: false,
      });
    }, 3500);
  };

  onPrint = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };

  render() {
    let {
      showingProgress,
      cardData,
      images,
      name,
      email,
      dob,
      day,
      month,
      year,
      errors,
    } = this.state;
    let welcomePage;
    let cardOrder;
    if (showingProgress) {
      welcomePage = (
        <>
          <div className="content sphinx-background scroll-snap-child">
            <Container className="sphinx-intro ">
              <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
              </div>
              <h5 className="sphinx-journey">Discovering Your Journey...</h5>
            </Container>
          </div>
        </>
      );
    } else if (errors.inactiveLink) {
      welcomePage = <></>;
    } else if (Object.keys(cardData).length === 0) {
      welcomePage = (
        <>
          <div className="content sphinx-background scroll-snap-child">
            <Container className="sphinx-intro ">
              <Row className="text-center">
                <Col xs="12">
                  <h5>The Sphinx Code</h5>
                </Col>
              </Row>
              <Row className="text-center my-4">
                <Col xs="12">
                  <h2>Destiny</h2>
                </Col>
              </Row>
              <hr width="100px" />
              <Row className="text-center mt-5">
                <Col xs="12">
                  <h5>Your Blueprint</h5>
                </Col>
              </Row>
              <Row className="logo-bottom bounce">
                <Col xs="12">
                  <img
                    className="logo-sm logo-top"
                    src={logo}
                    alt="Inner Truth Logo"
                  />
                  <img
                    className="logo-sm arrow-bottom"
                    src={arrow}
                    alt="Down Arrow"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content sphinx-background-dark scroll-snap-child">
            <Container className="sphinx-intro">
              <Row className="text-center form-title">
                <Col xs="12">
                  <h5 className="mb-3">Create Your Blueprint</h5>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={12} lg={5} md={7} sm={9} xs={11}>
                  <Form className="destiny-form" onSubmit={this.changeCards}>
                    <FormGroup row className="justify-content-center">
                      <Label
                        className="destiny-form-label"
                        for="name"
                        xl={2}
                        xs={12}
                      >
                        Name
                      </Label>
                      <Col xl={5} xs={12}>
                        <Input
                          className="blueprint-input"
                          onChange={this.onChange}
                          placeholder="Enter your name"
                          required
                          minLength="5"
                          value={name}
                          type="text"
                          name="name"
                          id="name"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="justify-content-center">
                      <Label
                        className="destiny-form-label"
                        for="email"
                        xl={2}
                        xs={12}
                      >
                        Email
                      </Label>
                      <Col xl={5} xs={12}>
                        <Input
                          className="blueprint-input"
                          onChange={this.onChange}
                          required
                          placeholder="Email Address"
                          value={email}
                          type="email"
                          name="email"
                          id="email"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="justify-content-center">
                      <Label
                        className="destiny-form-label"
                        for="day"
                        xl={2}
                        xs={12}
                      >
                        Date Of Birth
                      </Label>
                      <Col xl={1} xs={3}>
                        <Input
                          className="blueprint-input"
                          onChange={this.onChange}
                          placeholder="Day"
                          value={day}
                          type="number"
                          required
                          max="31"
                          min="1"
                          name="day"
                          id="day"
                        />
                      </Col>
                      <Col xl={2} xs={5}>
                        <Input
                          className="blueprint-input"
                          onChange={this.onChange}
                          type="select"
                          value={month}
                          name="month"
                          required
                          id="exampleSelect"
                          placeholder="Month"
                        >
                          <option value="" className="month">
                            Month
                          </option>
                          <option value={0}>Jan</option>
                          <option value={1}>Feb</option>
                          <option value={2}>Mar</option>
                          <option value={3}>Apr</option>
                          <option value={4}>May</option>
                          <option value={5}>Jun</option>
                          <option value={6}>Jul</option>
                          <option value={7}>Aug</option>
                          <option value={8}>Sep</option>
                          <option value={9}>Oct</option>
                          <option value={10}>Nov</option>
                          <option value={11}>Dec</option>
                        </Input>
                      </Col>
                      <Col xl={2} xs={4}>
                        <Input
                          className="blueprint-input"
                          onChange={this.onChange}
                          placeholder="Year"
                          value={year}
                          type="number"
                          required
                          min="1900"
                          max="2100"
                          name="year"
                          id="year"
                        />
                      </Col>
                    </FormGroup>
                    <hr className="form-separator" width="140px" />
                    <Row className="text-center">
                      <Col xs="12">
                        <Button type="submit" className="btn btn-confirm">
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row className="logo-bottom">
                <Col xs="12">
                  <img className="logo-sm" src={logo} alt="Inner Truth Logo" />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    } else {
      const { numbers } = cardData;
      cardOrder = numbers.map((id) => {
        return images.find((a) => {
          return a.id === id;
        });
      });
      welcomePage = (
        <>
          <div className="content white-background scroll-snap-child-cards">
            <Container className="text-center sphinx-container">
              <Row>
                <Col xs="12">
                  <h5 className="sphinx-title">The Sphinx Code</h5>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="12" className="dob">
                  <p>
                    {name}
                    <br />
                    Born {day} {moment(dob).format("MMM")} {year}
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1 mb-1">
                <Col xs="12">
                  <hr className="archtype-separator" width="100px" />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <h2 className="archtype-title">Your Inner Child</h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={8}>
                  <p className="arctype-description">
                    Your inner child is who you are as an archetype. It’s what
                    you’re here to bring to the world as an essence and as a
                    gift.
                  </p>
                </Col>
              </Row>
              <Row className="archtype-card-row">
                <Col xs="12">
                  <Card className="archtype-card archtype-dark">
                    <h4 className="card-title">{cardData.names[1]}</h4>
                    <div className="archtype-card-content">
                      <img
                        src={cardOrder[1].src}
                        alt="Your Inner Child"
                        className="sphinx-card-img"
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xl={10}>
                  <h5 className="keyword-title">Keywords:</h5>
                  <p className="keywords">{cardData.keywords[1]}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content white-background scroll-snap-child-cards">
            <Container className="text-center sphinx-container">
              <Row>
                <Col xs="12">
                  <h5 className="sphinx-title">The Sphinx Code</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1 mb-1">
                <Col xs="12"></Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <h2 className="archtype-title">Your Shadow Archetypes</h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={8}>
                  <p className="arctype-description">
                    Your shadow archetypes reveal the conditioning you’ve
                    inherited and show the patterns and limiting beliefs for
                    transformation.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-middle">
                <Col xs="6" className="my-auto">
                  <h4 className="card-title">{cardData.names[2]} (C)</h4>
                </Col>
                <Col xs="6" className="my-auto">
                  <h4 className="card-title">{cardData.names[5]} (F)</h4>
                </Col>
              </Row>
              <Row className="archtype-card-row">
                <Col xs="6" className="my-auto">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img
                        src={cardOrder[2].src}
                        alt="Your Shadow 1"
                        className="sphinx-card-img"
                      />
                    </div>
                  </Card>
                </Col>
                <Col xs="6" className="my-auto">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img
                        src={cardOrder[5].src}
                        alt="Your Shadow 2"
                        className="sphinx-card-img"
                      />
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row className="justify-content-center mt-3">
                <Col xs={6}>
                  <h5 className="keyword-title">Keywords:</h5>
                  <p className="keywords">{cardData.keywords[2]}</p>
                </Col>
                <Col xs={6}>
                  <h5 className="keyword-title">Keywords:</h5>
                  <p className="keywords">{cardData.keywords[5]}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content white-background scroll-snap-child-cards">
            <Container className="text-center sphinx-container">
              <Row>
                <Col xs="12">
                  <h5 className="sphinx-title">The Sphinx Code</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1 mb-1">
                <Col xs="12"></Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <h2 className="archtype-title">Your Harmonisation</h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={8}>
                  <p className="arctype-description">
                    This is the archetype you must align to in order to activate
                    your transformation out of shadow and into alignment.
                  </p>
                </Col>
              </Row>
              <Row className="archtype-card-row">
                <Col xs="12">
                  <Card className="archtype-card archtype-dark">
                    <h4 className="card-title">{cardData.names[7]}</h4>
                    <div className="archtype-card-content">
                      <img
                        src={cardOrder[7].src}
                        alt="Your Harmonization"
                        className="sphinx-card-img"
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xl={10}>
                  <h5 className="keyword-title">Keywords:</h5>
                  <p className="keywords">{cardData.keywords[7]}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content white-background scroll-snap-child-cards">
            <Container className="text-center sphinx-container">
              <Row>
                <Col xs="12">
                  <h5 className="sphinx-title">The Sphinx Code</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1 mb-1">
                <Col xs="12"></Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <h2 className="archtype-title">Your Transcendence</h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={8}>
                  <p className="arctype-description">
                    These show your soul’s qualities and how you will transform
                    as you align with your harmonisation archetype and move out
                    of shadow.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-middle">
                <Col xs="6" className="my-auto">
                  <h4 className="card-title">{cardData.names[10]} (K)</h4>
                </Col>
                <Col xs="6" className="my-auto">
                  <h4 className="card-title">{cardData.names[13]} (N)</h4>
                </Col>
              </Row>
              <Row className="archtype-card-row">
                <Col xs="6" className="my-auto">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img
                        src={cardOrder[10].src}
                        alt="Your Trascendence 1"
                        className="sphinx-card-img"
                      />
                    </div>
                  </Card>
                </Col>
                <Col xs="6" className="my-auto">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img
                        src={cardOrder[13].src}
                        alt="Your Trascendence 2"
                        className="sphinx-card-img"
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xs={6}>
                  <h5 className="keyword-title">Keywords:</h5>
                  <p className="keywords">{cardData.keywords[10]}</p>
                </Col>
                <Col xs={6}>
                  <h5 className="keyword-title">Keywords:</h5>
                  <p className="keywords">{cardData.keywords[13]}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content white-background scroll-snap-child-cards">
            <Container className="text-center sphinx-container">
              <Row>
                <Col xs="12">
                  <h5 className="sphinx-title">The Sphinx Code</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1 mb-1">
                <Col xs="12"></Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12}>
                  <h2 className="archtype-title">The Destiny Path</h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xl={8} lg={8}>
                  <p className="arctype-description">
                    This archetypal journey reveals the release of your inner
                    gifts (B/J) through transforming your shadow (C/F) by
                    aligning with your harmonisation (H) and moving into your
                    soul’s transcendence (K/N)
                  </p>
                </Col>
              </Row>
              <Row className="archtype-card-row">
                <Col xs="12">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img
                        src={EgoMap}
                        alt="The Ego Map"
                        className="ego-map-img"
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <Button className="pdf-button" onClick={this.onPrint}>
                    Click Here To Save PDF
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="scroll-snap">{welcomePage}</div>
      </>
    );
  }
}

export default Sphinx;
