import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/sphinx.scss";
import "./assets/css/index.css";
import "./assets/fonts/ProximaNova-Regular.otf";
import "./assets/fonts/ProximaNova-Bold.otf";
import "./assets/fonts/Futura-Regular.ttf";
import "./assets/fonts/Futura-Bold.ttf";

import LandingLayout from "./layouts/Landing";

// ReactDOM.render(<App />, document.getElementById('root'));

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/sphinx" render={(props) => <LandingLayout {...props} />} />
      <Redirect from="/" to="/sphinx" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
