import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Welcome from "../views/Welcome";
import Sphinx from "../views/Sphinx";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: "false"
    };
  }

  render() {
    return (
      <>
        {/* <Navbar className="landing-nav" sticky="top">
          <NavbarBrand className="landing-nav-brand" href="">
            Sphinx Code
          </NavbarBrand>
        </Navbar> */}
        <Switch>
          <Route path="/welcome" component={Welcome} />
          <Route path="/sphinx" component={Sphinx} />
        </Switch>
      </>
    );
  }
}

export default Landing;
