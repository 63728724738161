export const EgoMap = require("./EgoMap.jpg");
export const Magician = {
  id: 1,
  src: require("./cards/1MagicianDark.jpg"),
  title: "Magician",
};
export const HighPriestess = require("./cards/2HighPriestessDark.jpg");
export const Empress = require("./cards/3EmpressDark.jpg");
export const Emperor = require("./cards/4EmperorDark.jpg");
export const Hierophant = require("./cards/5HierophantDark.jpg");
export const Lover = require("./cards/6LoverDark.jpg");
export const Chariot = require("./cards/7ChariotDark.jpg");
export const Justice = require("./cards/8JusticeDark.jpg");
export const Hermit = require("./cards/9HermitDark.jpg");
export const WheelOfFortune = require("./cards/10WOFDark.jpg");
export const Force = require("./cards/11ForceDark.jpg");
export const HangedMan = require("./cards/12HangedManDark.jpg");
export const Death = require("./cards/13DeathDark.jpg");
export const Temperance = require("./cards/14TemperanceDark.jpg");
export const Devil = require("./cards/15DevilDark.jpg");
export const Tower = require("./cards/16TowerDark.jpg");
export const Star = require("./cards/17StarDark.jpg");
export const Moon = require("./cards/18MoonDark.jpg");
export const Sun = require("./cards/19SunDark.jpg");
export const Judgement = require("./cards/20JudgementDark.jpg");
export const World = require("./cards/21WorldDark.jpg");
export const Fool = require("./cards/22FoolDark.jpg");

const images = [
  { id: 1, src: require("./cards/1MagicianDark.jpg"), title: "Magician" },
  {
    id: 2,
    src: require("./cards/2HighPriestessDark.jpg"),
    title: "High Priestess",
  },
  { id: 3, src: require("./cards/3EmpressDark.jpg"), title: "Empress" },
  { id: 4, src: require("./cards/4EmperorDark.jpg"), title: "Emperor" },
  { id: 5, src: require("./cards/5HierophantDark.jpg"), title: "Priest" },
  { id: 6, src: require("./cards/6LoverDark.jpg"), title: "Lover" },
  { id: 7, src: require("./cards/7ChariotDark.jpg"), title: "Chariot" },
  { id: 8, src: require("./cards/8JusticeDark.jpg"), title: "Justice" },
  { id: 9, src: require("./cards/9HermitDark.jpg"), title: "Hermit" },
  { id: 10, src: require("./cards/10WOFDark.jpg"), title: "Wheel of Fortune" },
  { id: 11, src: require("./cards/11ForceDark.jpg"), title: "Force" },
  { id: 12, src: require("./cards/12HangedManDark.jpg"), title: "Hanged Man" },
  { id: 13, src: require("./cards/13DeathDark.jpg"), title: "Death" },
  { id: 14, src: require("./cards/14TemperanceDark.jpg"), title: "Temperance" },
  { id: 15, src: require("./cards/15DevilDark.jpg"), title: "Devil" },
  { id: 16, src: require("./cards/16TowerDark.jpg"), title: "Tower" },
  { id: 17, src: require("./cards/17StarDark.jpg"), title: "Star" },
  { id: 18, src: require("./cards/18MoonDark.jpg"), title: "Moon" },
  { id: 19, src: require("./cards/19SunDark.jpg"), title: "Sun" },
  { id: 20, src: require("./cards/20JudgementDark.jpg"), title: "Judgement" },
  { id: 21, src: require("./cards/21WorldDark.jpg"), title: "World" },
  { id: 22, src: require("./cards/22FoolDark.jpg"), title: "Fool" },
];

export default images;
