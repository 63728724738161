/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { Button, Container, Card, Row, Col } from 'reactstrap'

import images from '../assets/img/images'
import { EgoMap } from '../assets/img/images'
import { getData } from '../actions/apis'

class Welcome extends Component {
  constructor() {
    super();
    this.state = {
      images: [],
      cardData: {},
      name: '',
      fname: '',
      dob: '',
      day: '',
      month: '',
      year: '',
      errors: {}
    };
    this.changeCards = this.changeCards.bind(this);
  }

  componentDidMount() {
    this.setState({images});
    const vars = queryString.parse(this.props.location.search)
    const name = vars.name
    let nameFormatted = ''
    let fname = ''
    if(name) {
      nameFormatted = name.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase()+s.substring(1)).join(' ')
      fname = name.toLowerCase().split(' ').slice(0,1).map((s) => s.charAt(0).toUpperCase()+s.substring(1))
    }
    const dob = vars.dob
    const dobFormatted = moment(dob)
    const dobObject = moment(dobFormatted).toObject();
    const year = dobObject.years;
    const month = dobObject.months + 1;
    const day = dobObject.date;
    const dayFormatted = moment(dobFormatted).format("Do")
    const monthFormatted = moment(dobFormatted).format("MMMM")
    const yearFormatted = moment(dobFormatted).format("YYYY")
    this.setState({
      name: nameFormatted, fname, dob: dobFormatted, day: dayFormatted, month: monthFormatted, year: yearFormatted
    })
    let basicInputData = {
      birthDate: dobFormatted,
      birthYear: year,
      birthMonth: month,
      birthDay: day
    }
    this.changeCards(basicInputData)
  }

  changeCards(basicInputData) {
    getData(basicInputData).then(res => (
      this.setState({
        cardData: {
          numbers: res.numbers
        },
        showCardOutput: true
      })
    ))
  }

  onPrint = () => {
    window.print()
  }

  render() {
    const { cardData, images, name, fname, day, month, year, errors } = this.state;
    let welcomePage;
    let greeting;
    if (fname.length === 0) {
      greeting = <></>
    } else {
      greeting = `, ${fname}`
    }
    let cardOrder;
    if (errors.inactiveLink) {
      welcomePage = <></>
    } else if (Object.keys(cardData).length === 0) {
      welcomePage = <></>
    } else {
      const { numbers } = cardData;
      cardOrder = numbers.map(id => {
        return images.find((a) => {
          return a.id === id
        })
      })
      welcomePage = (
        <>
        <Container className="content" id="sphinx-overview">
          <Row className="text-center">
            <Col xs='12'>
              <h1>Welcome to The Sphinx Code{greeting}.</h1>
            </Col>
          </Row>
          <Row className="text-center mt-4">
            <Col xs='12'>
              <h2>Destiny Path</h2>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-4">
            <Col xs='9'>
              <p>The Destiny Path shows you your unique gifts, how to navigate limiting beliefs and how to manifest your dreams.</p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-1 mb-4">
            <Col xs='12'>
              <hr width="100px"/>
            </Col>
          </Row>
          <Row>
          <img src={EgoMap} alt="Sphinx Map" className="sphinx-card-img" />
          </Row>
          <Row className="justify-content-center mt-4 mb-4">
            <Col xs='12'>
              <hr width="100px"/>
            </Col>
          </Row>
          <Row className="text-center mt-4 archetype-section">
            <Col xs='12'>
              <h3>These are your <span style={{color:"#FDBF2D"}}>archetypes.</span></h3>
            </Col>
          </Row>
          <Row className="text-center mt-4">
            <Col xs='12'>
              <p>{name}
                <br />
              Born on {day} {month} {year}</p>
            </Col>
          </Row>
          <Row className="text-center my-4">
            <Col xs="6" md="4" className="my-4">
              <h5 className="archetype-title">Your Ego <br />Conditioning</h5>
              <Row className="archtype-card-row">
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[3].src} alt='Ego' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <h6>{cardOrder[3].title}</h6>
              <h6 className="archtype-description">
                Who you think you are based on your conditioning
              </h6>
              <hr className="archtype-separator" width="75px"/>
            </Col>
            <Col xs="6" md="4" className="my-4">
              <h5>Your <br />Inner Child</h5>
              <Row className="archtype-card-row">
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[1].src} alt='Child' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <h6>{cardOrder[1].title}</h6>
              <h6 className="archtype-description">
                Your true self and soul essence
              </h6>
              <hr className="archtype-separator" width="75px"/>
            </Col>
            <Col xs="6" md="4" className="my-4">
              <h5>Your <br />Shadow</h5>
              <Row className="archtype-card-row">
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[2].src} alt='Shadow 1' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[5].src} alt='Shadow 2'className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <h6>{cardOrder[2].title} + {cardOrder[5].title}</h6>
              <h6 className="archtype-description">
                The main lessons you are here to learn
              </h6>
              <hr className="archtype-separator" width="75px"/>
            </Col>
            <Col xs="6" md="4" className="my-4">
              <h5>Your <br />Harmonization</h5>
              <Row className="archtype-card-row">
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[7].src} alt='Harmonization' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <h6>{cardOrder[7].title}</h6>
              <h6 className="archtype-description">
                How to align to your highest truth
              </h6>
              <hr className="archtype-separator" width="75px"/>
            </Col>
            <Col xs="6" md="4" className="my-4">
              <h5>Your Keys of <br />Transformation</h5>
              <Row className="archtype-card-row">
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[10].src} alt='Transformation 1' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
                <Col xs="6">
                  <Card className="archtype-card archtype-dark">
                    <div className="archtype-card-content">
                      <img src={cardOrder[13].src} alt='Harmonization 2' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <h6>{cardOrder[10].title} + {cardOrder[13].title}</h6>
              <h6 className="archtype-description">
                How to align to your highest truth
              </h6>
              <hr className="archtype-separator" width="75px"/>
            </Col>
            <Col xs="6" md="4" className="my-4">
              <h5>Your <br />Soul Mission</h5>
              <Row className="archtype-card-row">
                <Col xs="6">
                  <Card className="archtype-card archtype-light">
                    <div className="archtype-card-content">
                      <img src={cardOrder[15].src} alt='Soul Mission' className="sphinx-card-img" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <h6>{cardOrder[15].title}</h6>
              <h6 className="archtype-description">
                How to choose work opportunities
              </h6>
              <hr className="archtype-separator" width="75px"/>
            </Col>
          </Row>
          <Row className="mt-4 mb-5">
            <Col xs="12" className="text-center">
              <Button className="pdf-button" onClick={this.onPrint}>Click Here To Save PDF</Button>
            </Col>
          </Row>
          </Container>      
          </>
        );
    }

    return (
      <div>
        {welcomePage}
      </div>
    );
  }
}


export default Welcome;
