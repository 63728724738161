import axios from "axios";

// const apiLinkUsed = 'http://localhost:8080'
// const apiLinkUsed = 'https://chdestiny.herokuapp.com'

export function getData(inputData) {
  return axios
    .post(`/api/cards/add`, { inputData })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function setUser(inputData) {
  return axios
    .post(`/api/users/create`, inputData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
